module GlobalApplicationData {
    let app = angular.module("app");

    app.factory("GlobalApplicationData", ["$window", "$q", function ($window: Window, $q: any) {
        let baseUrl = "";
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let thirdLevelDomainSSO = "";
        let thirdLevelDomainSSOReturn = "";
        if ($window && $window.location) {
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 80 : 443;
            protocol = window.location.protocol === "http:" ? "http" : "https";
            baseUrl = window.location.protocol + "//" + window.location.hostname + ":" + port + "/";
            let firstDot = 0;
            let lastDot = window.location.hostname.lastIndexOf(".");
            if (lastDot != -1) {
                firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
            }
            if (firstDot == -1) {
                firstDot = 0;
            }
            secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
            if (secondLevelDomain.charAt(0) == ".") {
                secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
            }
            // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
            if (window.location.hostname.indexOf("localhost") == 0) {
                thirdLevelDomain = "localhost.";
                thirdLevelDomainSSO = "localhosts.";
                thirdLevelDomainSSOReturn = "localhostl.";
            }
            else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
                thirdLevelDomain = "atf.";
                thirdLevelDomainSSO = "atfs.";
                thirdLevelDomainSSOReturn = "atfl.";
            }
            else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
                thirdLevelDomain = "test.";
                thirdLevelDomainSSO = "testsso.";
                thirdLevelDomainSSOReturn = "testalleanza.";
            }
            else {
                thirdLevelDomain = "www.";
                thirdLevelDomainSSO = "sso.";
                thirdLevelDomainSSOReturn = "alleanza.";
            }
        }
        let GlobalApplicationData = {

            // HINT-ALLOY : Definisco il prefisso applicativo (context) e le altre varibili del server
            applicationContext: baseUrl,
            isAliveAddress: "../index.html",
            isMaintenanceAddress: "../isMaintenance.xml",

            // Pagina per SSO con SAML e URL della pagina di login
            ssoRedirectPage: protocol + "://" + (secondLevelDomain.indexOf("libraryonline") >= 0 ? "alleanza.libraryonline.it/sso" : thirdLevelDomainSSO + secondLevelDomain) + "/login",
            ssoRedirectLogoutPage: protocol + "://" + (secondLevelDomain.indexOf("libraryonline") >= 0 ? "alleanza.libraryonline.it/sso" : thirdLevelDomainSSO + secondLevelDomain) + "/logout",
            ssoReturnPage: protocol + "://" + (secondLevelDomain.indexOf("libraryonline") >= 0 ? "alleanza.libraryonline.it" : thirdLevelDomainSSOReturn + secondLevelDomain) + "/#/app/localLogin",

            // Informazioni aggiuntive legate al token di autenticazione
            jwtPayload: <any>null,

            // Definisco l'array che conterrà tutte le possibili azioni a cui un utente è abilitato
            auths: <any>[],

            // Definisco l'array che conterrà le applicazioni abilitate all'utente
            applicationsEnabled: <any>[],

            // Definisco l'array per le lingue disponibili nell'applicazione che sarà popolato da authenticatingCtrl
            // L'attributo mandatory indica che è obbilgatoria e di default (infatti deve sempre essercene solo una di mandatory)
            langs: <any>[
                /*
                {lang: "en", label: "English (United States)", mandatory: true},
                {lang: "it", label: "Italiano (Italy)", mandatory: false}
                */
            ],

            // Millisecondi ogni quanto rinnovare il token utente (1 ora)
            //tokenRenewalMillis: 3600000,
            tokenRenewalMillis: 1800000, // mezz'ora
            // Id del timer associato al rinnovo del token
            tokenRenewalTimer: <any>null,

            // Tipo di device
            deviceType: <string>null,

            // UserAgent
            userAgent: <string>null
        };
        return GlobalApplicationData;
    }])
};